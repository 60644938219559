// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-formularz-zgloszeniowy-na-kurs-js": () => import("./../../../src/pages/formularz-zgloszeniowy-na-kurs.js" /* webpackChunkName: "component---src-pages-formularz-zgloszeniowy-na-kurs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kurs-kadry-i-place-js": () => import("./../../../src/pages/kurs-kadry-i-place.js" /* webpackChunkName: "component---src-pages-kurs-kadry-i-place-js" */),
  "component---src-pages-kurs-ksiegowosci-malych-firm-js": () => import("./../../../src/pages/kurs-ksiegowosci-malych-firm.js" /* webpackChunkName: "component---src-pages-kurs-ksiegowosci-malych-firm-js" */),
  "component---src-pages-kurs-samodzielny-ksiegowy-js": () => import("./../../../src/pages/kurs-samodzielny-ksiegowy.js" /* webpackChunkName: "component---src-pages-kurs-samodzielny-ksiegowy-js" */),
  "component---src-pages-kursy-ksiegowosci-js": () => import("./../../../src/pages/kursy-ksiegowosci.js" /* webpackChunkName: "component---src-pages-kursy-ksiegowosci-js" */),
  "component---src-pages-lokalizacja-js": () => import("./../../../src/pages/lokalizacja.js" /* webpackChunkName: "component---src-pages-lokalizacja-js" */),
  "component---src-pages-nasi-wykladowcy-js": () => import("./../../../src/pages/nasi-wykladowcy.js" /* webpackChunkName: "component---src-pages-nasi-wykladowcy-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-posterus-wroclaw-kursy-ksiegowosci-rachunkowosci-i-kadrowo-placowe-js": () => import("./../../../src/pages/posterus-wroclaw-kursy-ksiegowosci-rachunkowosci-i-kadrowo-placowe.js" /* webpackChunkName: "component---src-pages-posterus-wroclaw-kursy-ksiegowosci-rachunkowosci-i-kadrowo-placowe-js" */),
  "component---src-pages-regulamin-szkolen-js": () => import("./../../../src/pages/regulamin-szkolen.js" /* webpackChunkName: "component---src-pages-regulamin-szkolen-js" */),
  "component---src-pages-test-2-js": () => import("./../../../src/pages/test2.js" /* webpackChunkName: "component---src-pages-test-2-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

